<template>
  <el-drawer
    v-model="isShowEditDrawer"
    :show-close="false"
    modal-class="elv-report-transaction-overlay"
    class="elv-report-transaction-drawer-filter"
    :before-close="onCheckDrawerStatus"
    :size="360"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-transaction-drawer-filter-header__title">
        {{ t('common.filter') }}
      </h4>
      <div class="elv-report-transaction-drawer-filter-header-operating">
        <div class="elv-report-transaction-drawer-filter-header__clear">
          <SvgIcon name="reset" width="14" height="14" class="elv-report-transaction-drawer-filter-header__close" />
          <span @click="onClearData('')">{{ t('button.clear') }}</span>
        </div>
        <SvgIcon
          name="close"
          width="20"
          height="20"
          class="elv-report-transaction-drawer-filter-header__close"
          @click="onCheckDrawerStatus"
        />
      </div>
    </template>
    <el-scrollbar v-loading="loading" wrap-class="elv-transaction-drawer-filter-wrap">
      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>{{ t('report.category') }}</p>
          <span
            v-show="filterData?.journalTypeIds?.length || filterData.showSystem"
            @click="onClearData('journalTypeIds')"
            >{{ t('button.clear') }}</span
          >
        </div>
        <el-select
          v-model="filterData.journalTypeIds"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :class="{ 'is-unEmpty': filterData?.journalTypeIds?.length }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-transaction-filter-dropdown journal-type"
          :popper-append-to-body="false"
          style="width: 100%"
          @clear="onClearData('journalTypeIds')"
        >
          <template #tag>
            <el-tag
              v-for="journalTypeId in filterData.journalTypeIds?.length > 1
                ? filterData.journalTypeIds?.slice(0, 2)
                : filterData.journalTypeIds"
              :key="journalTypeId"
              closable
              disable-transitions
              @close="onClosableJournalTypeTag(journalTypeId)"
              >{{ journalTypeData(journalTypeId).value?.name }}</el-tag
            >
            <el-tooltip
              v-if="filterData.journalTypeIds?.length > 2"
              effect="light"
              placement="bottom"
              popper-class="elv-report-table-tips is-light elv-transaction-drawer-filter-tips"
              overlay-classname="elv-report-table-tips"
              :show-after="500"
            >
              <el-tag :key="filterData.journalTypeIds?.[3]" type="info" disable-transitions
                >+{{ filterData.journalTypeIds.length - 2 }}</el-tag
              >
              <template #content>
                <el-tag
                  v-for="journalTypeId in filterData.journalTypeIds?.slice(2)"
                  :key="journalTypeId"
                  closable
                  disable-transitions
                  type="info"
                  effect="light"
                  @close="onClosableJournalTypeTag(journalTypeId)"
                  >{{ journalTypeData(journalTypeId).value?.name }}</el-tag
                >
              </template>
            </el-tooltip>
          </template>
          <template #empty>
            <JournalTypeOverlayDropdown
              ref="journalTypeOverlayDropdownRef"
              v-model="filterData.journalTypeIds"
              multiple
              collapse-tags
              collapse-tags-tooltip
              :max-collapse-tags="2"
              :drop-down-data="journalTypeFilterList"
              @onChangeJournalTypeSelectAll="onChangeJournalTypeSelectAll"
            />
          </template>
        </el-select>
      </div>

      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>{{ t('common.type') }}</p>
          <span v-show="filterData?.type?.length" @click="onClearData('type')">{{ t('button.clear') }}</span>
        </div>
        <ElvSelect
          v-model="filterData.type"
          multiple
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          width="312px"
          :class="{ 'is-unEmpty': filterData?.type?.length }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-transaction-filter-dropdown"
          :popper-append-to-body="false"
          :options="tradeTypeList"
          style="width: 100%"
          @clear="onClearData('type')"
        />
      </div>

      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>{{ t('common.dateTime') }}</p>
          <span v-show="filterData?.datetime?.operator" @click="onClearData('datetime')">{{ t('button.clear') }}</span>
        </div>
        <el-select
          v-model="filterData.datetime.operator"
          :class="{
            'is-unEmpty': filterData?.datetime?.operator || filterData?.datetime?.value?.length,
            'is-select-value': filterData?.datetime?.operator
          }"
          class="is-not-underlined"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-transaction-filter-dropdown"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="onChangeDateType"
        >
          <el-option
            v-for="(i, index) in dateTimeOptions"
            :key="index"
            :label="capitalize(transformI18n(i?.label))"
            :value="i?.value"
          />
        </el-select>
        <div
          v-show="filterData?.datetime?.operator"
          class="elv-select-transaction-filter-date-picker"
          :class="{ 'is-unEmpty': filterData.datetime.value.length || filterData?.datetime?.operator }"
        >
          <DatePicker
            ref="datePickerRef"
            v-model:value="dateValue"
            :type="filterData?.datetime?.operator === 'BETWEEN' ? 'range' : 'date'"
            :picker="'date'"
            @change="onRangeChange"
          />
        </div>
      </div>

      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>{{ t('common.account') }}</p>
          <span
            v-show="accountType || filterData?.entityAccountId?.length || filterData?.accountType"
            @click="onClearData('account')"
            >{{ t('button.clear') }}</span
          >
        </div>
        <el-select
          v-model="accountType"
          :class="{
            'is-unEmpty': accountType || filterData?.entityAccountId.length || filterData.accountType,
            'is-select-value': accountType
          }"
          class="is-not-underlined"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-transaction-filter-dropdown"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="onChangeAccountType"
        >
          <el-option
            v-for="(i, index) in accountOptions"
            :key="index ?? i.value"
            :label="transformI18n(i.label)"
            :value="i.value"
          />
        </el-select>
        <div v-show="accountType">
          <el-select
            v-if="accountType === 'ACCOUNT_TYPE'"
            v-model="filterData.accountType"
            :class="{
              'is-unEmpty': filterData?.accountType || accountType,
              'is-select-value-top': accountType
            }"
            :placeholder="`${t('common.select')}...`"
            popper-class="elv-select-transaction-filter-dropdown"
            :popper-append-to-body="false"
            style="width: 100%"
          >
            <el-option
              v-for="(i, index) in accountTypeOptions"
              :key="index"
              :label="transformI18n(i.label)"
              :value="i.value"
            />
          </el-select>
          <ElvSelect
            v-else
            v-model="filterData.entityAccountId"
            width="312px"
            multiple
            clearable
            filterable
            collapse-tags
            collapse-tags-tooltip
            :max-collapse-tags="2"
            :placeholder="placeholderText"
            :class="{
              'is-unEmpty': filterData?.entityAccountId?.length || accountType,
              'is-select-value-top': accountType
            }"
            :options="accountNormalOptions"
            :collapse-list="[]"
          />
        </div>
      </div>

      <div class="elv-transaction-drawer-filter-item">
        <div class="elv-transaction-drawer-filter-item-title">
          <p>Trade ID</p>
          <span v-show="filterData?.tradeHash" @click="onClearData('tradeHash')">{{ t('button.clear') }}</span>
        </div>

        <div
          class="elv-transaction-drawer-filter-item-referenceNo"
          :class="{
            'is-unEmpty': filterData?.tradeHash
          }"
        >
          <el-input v-model="filterData.tradeHash" :placeholder="`Input ...`" />
        </div>
      </div>
    </el-scrollbar>
    <template #footer>
      <elv-button
        :loading="saveLoading"
        type="primary"
        round
        width="204"
        height="36"
        class="elv-report-transaction-drawer-filter-footer__save"
        @click="onSave"
      >
        {{ t('button.apply') }}
      </elv-button>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
// import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { tradeType } from '@/config/index'
import { $t, transformI18n } from '@/i18n/index'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import DatePicker from '../../components/DatePicker.vue'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useAccountStore } from '@/stores/modules/accounts'
import { transactionFilterSaveType } from '@/config/transactions'
import { find, map, groupBy, isEmpty, capitalize } from 'lodash-es'
import { useTransactionStore } from '@/stores/modules/transactions'
import JournalTypeOverlayDropdown from '@/pages/Financials/Project/components/JournalTypeOverlayDropdown.vue'

const props = defineProps({
  filterType: {
    type: String,
    default: transactionFilterSaveType.TRANSFER
  }
})

const emit = defineEmits(['onFilterData'])

const { t } = useI18n()
const route = useRoute()
const accountStore = useAccountStore()
const transactionStore = useTransactionStore()

const dateValue = ref()
const loading = ref(false)
const accountType = ref('')
const saveLoading = ref(false)
const isShowEditDrawer = ref(false)

const datePickerRef = useTemplateRef('datePickerRef')
const journalTypeOverlayDropdownRef = useTemplateRef('journalTypeOverlayDropdownRef')
const { transactionCurrencyList } = storeToRefs(transactionStore)

const filterData: any = ref({
  type: [],
  currency: [],
  datetime: {
    operator: '',
    value: []
  },
  entityAccountId: [],
  journalTypeIds: [],
  accountType: '',
  verifyStatus: '',
  amount: {
    operator: '',
    value: []
  },
  amountFC: {
    operator: '',
    value: []
  },
  tradeHash: ''
})

const currencySelectData: any = ref({
  checkAllCrypto: false,
  checkAllFiat: false,
  checkAllPair: false,
  isIndeterminateCrypto: false,
  isIndeterminateFiat: false,
  isIndeterminatePair: false,
  lastTimeData: []
})

const dateTimeOptions = [
  { label: $t('common.on'), value: 'ON' },
  { label: $t('common.after'), value: 'AFTER' },
  { label: $t('common.before'), value: 'BEFORE' },
  { label: $t('common.between'), value: 'BETWEEN' }
]

const accountOptions = [
  { label: $t('common.account'), value: 'ACCOUNT' },
  { label: $t('common.accountType'), value: 'ACCOUNT_TYPE' }
]

const accountTypeOptions = [
  { label: $t('common.onChain'), value: 'CHAIN' },
  { label: $t('common.bank'), value: 'BANK' },
  { label: $t('common.exchange'), value: 'EXCHANGE' },
  { label: $t('common.custody'), value: 'CUSTODY' },
  { label: $t('common.payment'), value: 'PAYMENT' }
]

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const placeholderText = computed(() => {
  return `${t('common.select')}...`
})

const tradeTypeList = computed(() => {
  const currentList: Array<any> = tradeType.map((item: any) => {
    return {
      label: item.label,
      value: item.value
    }
  })
  return currentList
})

const entityFilterData = computed(() => {
  const filterSaveData = transactionStore.entityFilterList.find((item: any) => {
    return item.type === props.filterType
  })
  if (filterSaveData) {
    return (
      filterSaveData.list.find((item: any) => {
        return item.entityId === entityId.value
      })?.data ?? {}
    )
  }
  return {}
})

const generalJournalTypeId = computed(() => {
  const generalJournal: any = find(transactionStore.journalTypeList, { slug: 'general_journal' })
  return generalJournal?.journalTypeId ?? ''
})

const journalTypeFilterList = computed(() => {
  const list = transactionStore
    .journalTypeFilterList('filter')
    ?.filter((item: any) => item.group !== 'GAIN_OR_LOSS_ADJUSTING')
  list.push({
    groupName: $t('report.generalJournal'),
    group: 'GENERAL_JOURNAL',
    list: [
      {
        journalTypeId: generalJournalTypeId.value,
        name: 'General journal',
        nameCN: '普通日记账'
      }
    ]
  })
  list.push({
    groupName: $t('common.uncategorized'),
    group: 'UNCATEGORIZED',
    list: [
      {
        journalTypeId: '0',
        name: 'Uncategorized',
        nameCN: '未分类'
      }
    ]
  })
  return list
})

const currencyOptions = computed(() => {
  const currencyGroupList = groupBy(transactionCurrencyList.value, 'type')
  return map(currencyGroupList, (items, type) => {
    const label = type === 'CRYPTO' ? $t('title.allCrypto') : $t('title.allFiat')
    const list = items.map(({ symbol, showSymbol, logo }) => ({
      label: showSymbol,
      value: `${type === 'CRYPTO' ? 'Crypto' : 'fiat'}__${symbol}`,
      logo
    }))
    return { label, type: type === 'CRYPTO' ? 'Crypto' : 'fiat', list }
  })
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item: any) => {
    return item.status === 'NORMAL'
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform?.logo,
      isCheck: false
    }
  })
})

const journalTypeData: any = useComputedHook((journalTypeId: string) => {
  const journalTypeList = [
    ...transactionStore.journalTypeList,
    {
      journalTypeId: '0',
      name: 'Uncategorized',
      nameCN: '未分类'
    }
  ]
  return find(journalTypeList, { journalTypeId }) ?? {}
})

/**
 * 清除Category选择器选择的某一标签
 * @param categoryId   删除的 categoryId
 */
const onClosableJournalTypeTag = (journalTypeId: string) => {
  filterData.value.journalTypeIds = filterData.value.journalTypeIds.filter((item: string) => item !== journalTypeId)
}

/**
 *JournalType选择器变化触发
 */
const onChangeJournalTypeSelectAll = (journalTypeIds: string[]) => {
  filterData.value.journalTypeIds = journalTypeIds
}

/**
 * 清除筛选数据
 * @param type 筛选类型
 */
const onClearData = (type: string) => {
  switch (type) {
    case 'journalTypeIds':
      journalTypeOverlayDropdownRef.value?.clearSelectedAllList()
      filterData.value.journalTypeIds = []
      break
    case 'type':
      filterData.value.type = []
      break
    case 'datetime':
      filterData.value.datetime = {
        operator: '',
        value: []
      }
      dateValue.value = ''
      break
    case 'verifyStatus':
      filterData.value.verifyStatus = ''
      break
    case 'amount':
      filterData.value.amount = {
        operator: '',
        value: []
      }
      break
    case 'amountFC':
      filterData.value.amountFC = {
        operator: '',
        value: []
      }
      break
    case 'tradeHash':
      filterData.value.tradeHash = ''
      break
    case 'currency':
      filterData.value.currency = []
      currencySelectData.value = {
        checkAllCrypto: false,
        checkAllFiat: false,
        checkAllPair: false,
        isIndeterminateCrypto: false,
        isIndeterminateFiat: false,
        isIndeterminatePair: false,
        lastTimeData: []
      }
      break
    case 'account':
      filterData.value.accountId = []
      filterData.value.accountType = ''
      accountType.value = ''
      filterData.value.entityAccountId = []
      break
    default:
      filterData.value = {
        type: [],
        currency: [],
        datetime: {
          operator: '',
          value: []
        },
        journalTypeIds: [],
        entityAccountId: [],
        accountType: '',
        verifyStatus: '',
        amount: {
          operator: '',
          value: []
        },
        amountFC: {
          operator: '',
          value: []
        },
        tradeHash: ''
      }
      currencySelectData.value = {
        checkAllCrypto: false,
        checkAllFiat: false,
        checkAllPair: false,
        isIndeterminateCrypto: false,
        isIndeterminateFiat: false,
        isIndeterminatePair: false,
        lastTimeData: []
      }
      dateValue.value = ''
      accountType.value = ''
      break
  }
}

/**
 * 筛选日期变化触发
 * @param val 日期值
 */
const onChangeDateType = (val: string) => {
  filterData.value.datetime.value = []
  if (val === 'BETWEEN') {
    dateValue.value = []
  } else {
    dateValue.value = ''
  }
}

/**
 * amount数值变化触发
 * @param val amount范围类型
 * @param key amount具体数值
 */
const onChangeAccountType = () => {
  filterData.value.accountId = []
  filterData.value.accountType = ''
  filterData.value.entityAccountId = []
}

/**
 * 日期选择器变化触发
 * @param value 选择的日期
 */
// eslint-disable-next-line no-unused-vars
const onRangeChange = (value: any) => {
  if (filterData.value.datetime.operator === 'BETWEEN') {
    filterData.value.datetime.value = value !== null ? value : []
  } else {
    filterData.value.datetime.value = value !== null ? [value] : []
  }
}

/**
 * 更改筛选器页面显隐状态
 */
const onCheckDrawerStatus = () => {
  isShowEditDrawer.value = !isShowEditDrawer.value
}

/**
 * 保存筛选数据，进行Trade筛选
 */
const onSave = async () => {
  try {
    saveLoading.value = true
    const newFilterData = JSON.parse(JSON.stringify(filterData.value))
    Object.keys(newFilterData).forEach((item: any) => {
      if (Array.isArray(newFilterData[item]) && !newFilterData[item].length) {
        delete newFilterData[item]
      } else if (['datetime', 'amount', 'amountFC'].includes(item)) {
        if (
          !newFilterData[item].operator ||
          !newFilterData[item].value.length ||
          newFilterData[item].value.some((i: any) => !i)
        ) {
          delete newFilterData[item]
        }
      } else if (!newFilterData[item]) {
        delete newFilterData[item]
      }
    })
    transactionStore.editEntityFilter(entityId.value, newFilterData, props.filterType)
    emit('onFilterData')
    onCheckDrawerStatus()
  } catch (error: any) {
    ElMessage.error(error.message)
    console.error(error)
  } finally {
    saveLoading.value = false
  }
}

watch(
  () => isShowEditDrawer.value,
  () => {
    if (isShowEditDrawer.value) {
      nextTick(() => {
        if (!isEmpty(entityFilterData.value)) {
          filterData.value = {
            type: entityFilterData.value?.type ?? [],
            currency: entityFilterData.value?.currency ?? [],
            datetime: entityFilterData.value?.datetime?.operator
              ? entityFilterData.value?.datetime
              : {
                  operator: '',
                  value: []
                },
            entityAccountId: entityFilterData.value?.entityAccountId ?? [],
            accountType: entityFilterData.value?.accountType ?? '',
            verifyStatus: entityFilterData.value?.verifyStatus ?? '',
            journalTypeIds: entityFilterData.value?.journalTypeIds ?? [],
            amount: entityFilterData.value?.amount?.operator
              ? entityFilterData.value?.amount
              : {
                  operator: '',
                  value: []
                },
            amountFC: entityFilterData.value?.amountFC?.operator
              ? entityFilterData.value?.amountFC
              : { operator: '', value: [] },
            tradeHash: entityFilterData.value?.tradeHash ?? ''
          }
          if (entityFilterData.value?.entityAccountId?.length) {
            accountType.value = 'ACCOUNT'
          } else if (entityFilterData.value?.accountType) {
            accountType.value = 'ACCOUNT_TYPE'
          }
          if (entityFilterData.value?.datetime?.operator) {
            dateValue.value =
              entityFilterData.value?.datetime?.operator === 'BETWEEN'
                ? entityFilterData.value?.datetime?.value
                : entityFilterData.value?.datetime?.value[0]
          }
          if (entityFilterData.value?.currency?.length) {
            currencySelectData.value = {
              checkAllCrypto: false,
              checkAllFiat: false,
              checkAllPair: false,
              isIndeterminateCrypto: false,
              isIndeterminateFiat: false,
              isIndeterminatePair: false,
              lastTimeData: entityFilterData.value?.currency
            }
            entityFilterData.value?.currency.forEach((item: any) => {
              const type = capitalize(item.split('__')[0])
              const typeDataLength =
                entityFilterData.value?.currency?.filter((i: any) => capitalize(i.split('__')[0]) === type).length ?? 0
              currencySelectData.value[`checkAll${type}`] =
                currencyOptions.value.find((i: any) => capitalize(i.type) === type)?.list.length === typeDataLength
              currencySelectData.value[`isIndeterminate${type}`] = !!(
                (typeDataLength > 0 &&
                  (entityFilterData.value?.currency ?? []).filter((i: any) => capitalize(i.split('__')[0]) === type)
                    .length <
                    (currencyOptions.value.find((i: any) => capitalize(i.type) === type)?.list.length ?? 0)) ||
                false
              )
            })
          }
        } else {
          onClearData('')
        }
      })
    }
  }
)

defineExpose({ onCheckDrawerStatus })
</script>

<style lang="scss" scoped>
.elv-report-transaction-drawer-filter {
  .elv-report-transaction-drawer-filter-header__title {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1e2024;
    margin: 0;
  }

  .elv-report-transaction-drawer-filter-header-operating {
    display: flex;
    align-items: center;
  }

  .elv-report-transaction-drawer-filter-header__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;

    span {
      display: block;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 6px;
      padding-right: 8px;
      border-right: 1px solid #dde1e6;
    }
  }

  .elv-report-transaction-drawer-filter-header__close {
    padding: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-transaction-drawer-filter {
  .el-drawer__header {
    padding: 10px 0px;
    margin: 0 24px;
    border-bottom: 1px solid #d0d4d9;
    height: 48px;
    box-sizing: border-box;
    display: flex;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .elv-transaction-drawer-filter-wrap {
    padding: 0px 24px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .elv-transaction-drawer-filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .elv-transaction-drawer-filter-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 4px;
      height: 24px;

      p {
        color: #1e2024;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: #5e85eb;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .elv-select-transaction-filter-date-picker {
      border-radius: 0px 0px 4px 4px;
      border: 1px solid #dde1e6;
      box-sizing: border-box;
      height: 44px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

      &.is-unEmpty {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }

    .elv-select-transaction-filter-amount {
      display: flex;

      .el-input .el-input__wrapper {
        border-radius: 0px 0px 4px 4px;
        border: 1px solid #dde1e6;
        box-sizing: border-box;
        height: 44px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      }

      &.is-unEmpty .el-input .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }

      &.is-between .el-input {
        &:first-of-type .el-input__wrapper {
          border-bottom-right-radius: 0px;
          border-right: 0px;
          position: relative;
          z-index: 20;

          &:after {
            content: '-';
            position: absolute;
            right: -4px;
            top: 50%;
            transform: translateY(-50%);
            width: fit-content;
            height: 16px;
            color: #1e2024;
            background-color: #f7f9fe;
            text-align: center;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:last-of-type .el-input__wrapper {
          border-bottom-left-radius: 0px;
        }
      }
    }

    .elv-transaction-drawer-filter-item-referenceNo {
      &.is-unEmpty .el-input .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }
  }

  .elv-report-transaction-drawer-filter-info {
    margin-bottom: 16px;

    .elv-report-transaction-drawer-filter-info__title {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;

      span {
        width: 1px;
        height: 12px;
        display: block;
        background: #dde1e6;
        margin: 0 6px;
      }
    }
  }
}

.el-select.is-unEmpty {
  &.is-not-underlined {
    .el-select__wrapper.is-hovering {
      border-bottom: 0px !important;
    }
  }

  .el-select__wrapper {
    border-color: #5e85eb;
    background-color: #f7f9fe;
    min-height: 44px;
    height: fit-content;

    &.is-hovering {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-tag__content {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        .el-select__tags-text {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        color: #aaafb6;
        margin-left: 2px;

        &:hover {
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }

  .el-select__selected-item {
    display: flex;

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-select__tags-text {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}

.el-select.is-select-value {
  .el-select__wrapper {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 0px;
  }
}

.el-select.is-select-value-top {
  .el-select__wrapper {
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }
}

.elv-transaction-drawer-filter-tips.el-popper {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;
}

.elv-select-transaction-filter-dropdown {
  .elv-select-transaction-filter-type__all,
  .elv-select-transaction-filter-currency__all {
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    margin-right: 0px;
    border-top: 1px solid #edf0f3;
    border-bottom: 1px solid #edf0f3;

    &:first-of-type {
      border-top: 0px;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .el-checkbox-group {
    .el-checkbox {
      .el-checkbox__label {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }

  &.journal-type {
    padding: 0px;

    .el-select-dropdown__empty {
      padding: 0px;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    padding-left: 8px;

    &.selected::after,
    &.is-selected::after {
      width: 0;
      height: 0;
    }
  }
}
</style>
